import { useEffect, useState } from "react";
import sanityClient from "../../client";
import ImageUrlBuilder from "@sanity/image-url";

import "./index.css";

export const Home = () => {
  const [data, setData] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(0);

  const builder = ImageUrlBuilder(sanityClient);
  const urlFor = (source) => {
    return builder.image(source);
  };

  const allImagesLoaded = () => {
    setImagesLoaded((prevLoaded) => prevLoaded + 1);
  };

  const fetchData = async () => {
    try {
      const query = `*[_type == 'home'][0]`;
      const result = await sanityClient.fetch(query);
      setData(result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    document.title = "HOME";
  }, []);

  const totalImages = data && data.backgroundImage ? 1 : 0;

  return (
    <>
      <section className="home-page">
        <article
          className={`quote-container ${
            imagesLoaded === totalImages ? "loaded" : ""
          }`}
        >
          {data && data.backgroundImage && (
            <img
              src={urlFor(data.backgroundImage.asset._ref).url()}
              onLoad={allImagesLoaded}
              alt="Background"
            />
          )}
          {data && data.quote && <h1>{data.quote}</h1>}
        </article>
      </section>
    </>
  );
};
