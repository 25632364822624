import { useState, useEffect, useMemo } from "react";
import sanityClient from "../../client";
import ImageUrlBuilder from "@sanity/image-url";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import "./index.css";

export const Gallery = () => {
  const [data, setData] = useState([]);
  const builder = useMemo(() => ImageUrlBuilder(sanityClient), []);
  const urlFor = useMemo(() => {
    return (source) => builder.image(source);
  }, [builder]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == 'gallery'][0]`;
        const result = await sanityClient.fetch(query);
        setData(result);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    document.title = "GALLERY";
  }, []);

  return (
    <>
      <section className="gallery-page">
        <h1>GALLERY</h1>
        <div className="gallery">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry>
              {data.media &&
                data.media.map((item, index) => {
                  return (
                    <img
                      key={index}
                      src={urlFor(item.asset._ref).url()}
                      loading="lazy"
                    />
                  );
                })}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </section>
    </>
  );
};
