import { useEffect, useState, useMemo } from "react";
import sanityClient from "../../client";
import ImageUrlBuilder from "@sanity/image-url";

import "./index.css";

export const Projects = () => {
  const [data, setData] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(0);

  const builder = useMemo(() => ImageUrlBuilder(sanityClient), []);
  const urlFor = useMemo(() => {
    return (source) => builder.image(source);
  }, [builder]);

  const allImagesLoaded = () => {
    setImagesLoaded((prevLoaded) => prevLoaded + 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == 'projects']|order(orderRank)`;
        const result = await sanityClient.fetch(query);
        setData(result);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    document.title = "PROJECTS";
  }, []);

  return (
    <>
      <section className="projects-page">
        {imagesLoaded !== data.length && (
          <h1 className="loading-text">{imagesLoaded} Projects loaded </h1>
        )}
        {imagesLoaded === data.length && (
          <h1 className="title">Select Projects</h1>
        )}
        <div
          className={`projects-container ${
            imagesLoaded === data.length ? "loaded" : ""
          }`}
        >
          {data && data.length > 0 ? (
            data.slice().map((project, index) => {
              return (
                <>
                  <a
                    key={index}
                    href={`/projects/${project.project_title
                      .toLowerCase()
                      .replaceAll(" ", "-")}/${project._id}`}
                  >
                    <div className={`project`} key={index}>
                      <div className="cover">
                        <img
                          src={urlFor(project.project_cover.asset._ref).url()}
                          loading="lazy"
                          onLoad={() => {
                            allImagesLoaded();
                          }}
                        />
                        <div className="overlay">
                          <h2>{project.project_subheading}</h2>
                        </div>
                      </div>
                    </div>
                  </a>
                </>
              );
            })
          ) : (
            <h1>Loading</h1>
          )}
        </div>
      </section>
    </>
  );
};
