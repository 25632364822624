import { useEffect, useState, useMemo } from "react";
import sanityClient from "../../client";
import ImageUrlBuilder from "@sanity/image-url";
import { renderText } from "../../functions";

import "./index.css";

export const News = () => {
  const [data, setData] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(0);

  const builder = useMemo(() => ImageUrlBuilder(sanityClient), []);
  const urlFor = useMemo(() => {
    return (source) => builder.image(source);
  }, [builder]);

  const allImagesLoaded = () => {
    setImagesLoaded((prevLoaded) => prevLoaded + 1);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );

    const day = date.getDate() + 1;
    const ordinalSuffix = (n) => {
      const s = ["th", "st", "nd", "rd"];
      const v = n % 100;
      return s[(v - 20) % 10] || s[v] || s[0];
    };

    return formattedDate.replace(/\d+/, `${day}${ordinalSuffix(day)}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == 'news'][0]`;
        const result = await sanityClient.fetch(query);
        setData(result);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  console.log(data);

  return (
    <section className="news-page">
      <h1 className="title">NOTES FROM THE UNDERGROUND</h1>
      {imagesLoaded !== data.posts?.length && (
        <h1 className="loading-text">{imagesLoaded} Posts loaded </h1>
      )}
      <section
        className={`news-posts ${
          imagesLoaded === data.posts?.length ? "loaded" : ""
        }`}
      >
        {data &&
          data.posts &&
          data.posts
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .map((post, index) => {
              return (
                <div className="post" index={index}>
                  <div className="cover">
                    <img
                      src={urlFor(post.thumbnail.asset._ref).url()}
                      onLoad={() => {
                        allImagesLoaded();
                      }}
                      loading="lazy"
                    />
                  </div>
                  <div className="post-copy-container" key={index}>
                    <h2 className="title">{post.title || "Post Title"}</h2>
                    {post.date && (
                      <p className="date">{formatDate(post.date)}</p>
                    )}
                    <div className="description">
                      {post.blocks.map((block) => {
                        if (
                          block.textBlock !== undefined &&
                          block.textBlock[0]?.children
                        ) {
                          return renderText(block);
                        }
                        return null;
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
      </section>
    </section>
  );
};
