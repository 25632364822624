import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Header } from "./Components/Header";
import { SizzleReel } from "./Components/SizzleReel";
import { Stars } from "./Components/Stars";
import { Footer } from "./Components/Footer";

import { Home } from "./Pages/Home";
import { Projects } from "./Pages/Projects";
import { Project } from "./Pages/Project";
import { Services } from "./Pages/Services";
import { About } from "./Pages/About";
import { Vision } from "./Pages/Vision";
import { Gallery } from "./Pages/Gallery";
import { Fashion } from "./Pages/Fashion";
import { Show } from "./Pages/Show";
import { News } from "./Pages/News";

export const App = () => {
  return (
    <>
      <main className="inner-page">
        <BrowserRouter>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/news" element={<News />} />
            <Route path="/projects" element={<Projects />} />
            <Route
              path="/projects/:projectTitle/:projectId"
              element={<Project />}
            />
            <Route path="/services" element={<Services />} />
            <Route path="/vision" element={<Vision />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/fashion" element={<Fashion />} />
            <Route path="/fashion/:show" element={<Show />} />
          </Routes>
          <SizzleReel />
          <Stars />
          <Footer />
        </BrowserRouter>
      </main>
    </>
  );
};
