import { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../../client";
import ImageUrlBuilder from "@sanity/image-url";

import "./index.css";

export const Project = () => {
  const [project, setProject] = useState(null);
  const [imagesLoaded, setImagesLoaded] = useState(0);

  const { projectId } = useParams();

  const builder = useMemo(() => ImageUrlBuilder(sanityClient), []);
  const urlFor = useMemo(() => {
    return (source) => builder.image(source);
  }, [builder]);

  const allImagesLoaded = () => {
    setImagesLoaded((prevLoaded) => prevLoaded + 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `*[_type == 'projects']`;
        const result = await sanityClient.fetch(query);

        const matchingProject = result.find((proj) => proj._id === projectId);
        setProject(matchingProject);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [projectId]);

  useEffect(() => {
    if (project) {
      document.title = project.project_title;
    }
  }, [project]);

  return (
    <>
      <section className="project-page">
        {project ? (
          <>
            {project.project_media && (
              <div className="media-container">
                {project.project_media.map((item, index) => {
                  return (
                    <img
                      key={index}
                      src={urlFor(item.asset._ref).url() ?? ""}
                    />
                  );
                })}
              </div>
            )}
            <div className="project-details">
              <div className="header">
                <h1>
                  {project.project_title || "Project Title not available"}
                </h1>
                <h3>
                  {project.project_subheading ||
                    "Project Subheading not available"}
                </h3>
              </div>
              <div className="details">
                <p>
                  {project.project_description ||
                    "Project Description not available"}
                </p>
                {project.project_roles && (
                  <ul>
                    {project.project_roles.map((role, index) => {
                      return <li key={index}>{role}</li>;
                    })}
                  </ul>
                )}
              </div>
              {project.project_link && <a href={project.project_link}>VIEW</a>}
            </div>
          </>
        ) : (
          <h1>Project Loading </h1>
        )}
      </section>
    </>
  );
};
